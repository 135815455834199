<template lang="pug">
.team-status
  p You're working in the {{ activeTeam.name }} space &nbsp;
    nuxt-link.tag(to="/") Switch
  //- {{ activeTeam.tenantId}}
</template>

<script>
export default {
  data() {
    return {
      // activeTeam: ''
    };
  },
  computed: {
    activeTeam() {
      return this.$store.state.team.activeTeam;
    },
  },
};
</script>

<style lang="scss">
.team-status {
  // position: fixed;
  // top: 0;
  // right: 0;
  // left: rempx(91);
  text-align: center;
  padding: rempx(4);
  background: #555;
  color: #fff;
}
</style>
